/* eslint-disable object-curly-newline */
import { Helmet } from "react-helmet";

function Seo({ description, lang, meta, title }) {
  // eslint-disable-next-line operator-linebreak
  const metaDescription =
    description || "Opera con nosotros y empieza a ganaren un clic.";
  const defaultTitle = "FxBravo";

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: "description",
          content: metaDescription
        },
        {
          property: "og:title",
          content: title
        },
        {
          property: "og:description",
          content: metaDescription
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          name: "twitter:card",
          content: "summary"
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:description",
          content: metaDescription
        }
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: "en",
  meta: [],
  description: ""
};
export default Seo;
