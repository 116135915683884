/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";

import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/pagination";
import "@stylesPages/Home.scss";

import Logo from "@images/logo.svg";
import WhatImage from "@images/what-image.png";
import BitcoinImage from "@images/bitcoin-shadow.png";
import InvestImage from "@images/invest-image.png";
import YenImage from "@images/yen-shadow.png";
import WorldVideo from "@images/world-video.mp4";
import ReasonOneImage from "@images/reason-one.png";
import ReasonTwoImage from "@images/reason-two.png";
import ReasonThreeImage from "@images/reason-three.png";
import ReasonFourImage from "@images/reason-four.png";
import LiteImage from "@images/lite-shadow.png";
import MoneyImage from "@images/money-shadow.png";
import IconCurrency from "@images/currency-reviews.svg";
import IconCurrency2 from "@images/currency2-reviews.svg";
import ReviewPeople1 from "@images/review-people1.png";
import ReviewPeople2 from "@images/review-people2.png";
import ReviewPeople3 from "@images/review-people3.png";
import ReviewPeople4 from "@images/review-people4.png";
import ReviewPeople5 from "@images/review-people5.png";
import ReviewPeople6 from "@images/review-people6.png";
import StepsOneImage from "@icons/steps-one.svg";
import StepsTwoImage from "@icons/steps-two.svg";
import StepsThreeImage from "@icons/steps-three.svg";
import StepsFourImage from "@icons/steps-four.svg";

import Seo from "@components/Seo";
import Header from "@components/Header";
import Footer from "@components/Footer";

SwiperCore.use([Pagination]);

export default function Index() {
  const { t } = useTranslation("home");
  const [reason, setReason] = useState("one");

  useEffect(() => {
    (async () => {
      const request = await fetch("https://api.ipregistry.co/?key=ccu1zlzhgetyu5");
      const response = await request.json();
      if (
        (response.location.country.code === "US")
        || (response.location.country.code === "CA")
        || (response.location.country.code === "IL")
      ) {
        window.location.replace("https://www.google.com/");
      }
    })();
  }, []);

  return (
    <>
      <Seo title="Inicio" />
      <Header />
      <main className="home">
        <section className="home__hero">
          <div className="home__hero-container">
            <div className="home__hero-box">
              <img src={Logo} alt="FXBravo" className="home__hero-logo" />
            </div>
            <div className="home__hero-box">
              <div className="home__hero-text">
                <h1 className="home__hero-title">{t("hero-title")}</h1>
                <p className="home__hero-subtitle">{t("hero-subtitle")}</p>
                <span className="home__hero-cta">
                  <Link
                    to="/platform"
                    type="button"
                    className="home__hero-button"
                  >
                    {t("hero-learn")}
                  </Link>
                  <a
                    href="https://clientzone.fxbravo.net/register"
                    className="home__hero-button"
                  >
                    {t("hero-start")}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="home__what">
          <div className="home__what-container">
            <div className="home__what-box">
              <div className="home__what-text">
                <img
                  src={BitcoinImage}
                  alt="Bitcoin"
                  className="home__what-shadow"
                />

                <h2 className="home__what-title">{t("what-title")}</h2>
                <p className="home__what-paragraph">{t("what-paragraph")}</p>
              </div>
            </div>
            <div className="home__what-box">
              <img src={WhatImage} alt="What is" className="home__what-image" />
            </div>
          </div>
        </section>
        <section className="home__invest">
          <div className="home__invest-container">
            <div className="home__invest-text">
              <h2 className="home__invest-title">{t("invest-title")}</h2>
              <p className="home__invest-paragraph">{t("invest-paragraph")}</p>
            </div>
            <img
              src={InvestImage}
              alt="Invest"
              className="home__invest-image"
            />
            <img src={YenImage} alt="Yen" className="home__invest-shadow" />
          </div>
        </section>
        <section className="home__market">
          <video className="home__market-video" autoPlay muted loop>
            <source src={WorldVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="home__market-container">
            <h2 className="home__market-title">{t("market-title")}</h2>
            <p className="home__market-paragraph">{t("market-paragraph")}</p>
            <Link to="/platform" type="button" className="home__market-button">
              {t("hero-learn")}
            </Link>
          </div>
        </section>
        <section className="home__reasons">
          <div className="home__reasons-container">
            <img
              src={LiteImage}
              alt="Litecoin"
              className="home__reasons-shadow home__reasons-shadow--top"
            />
            <img
              src={MoneyImage}
              alt="Money"
              className="home__reasons-shadow home__reasons-shadow--bottom"
            />
            <div className="home__reasons-box home__reasons-box--image">
              {reason === "one" && (
                <img
                  src={ReasonOneImage}
                  alt="easy to use"
                  className="home__reasons-image"
                />
              )}
              {reason === "two" && (
                <img
                  src={ReasonTwoImage}
                  alt="easy funds"
                  className="home__reasons-image"
                />
              )}
              {reason === "three" && (
                <img
                  src={ReasonThreeImage}
                  alt="support 24/7"
                  className="home__reasons-image"
                />
              )}
              {reason === "four" && (
                <img
                  src={ReasonFourImage}
                  alt="renamed platform"
                  className="home__reasons-image"
                />
              )}
            </div>
            <div className="home__reasons-box">
              <h2 className="home__reasons-title">{t("reasons-title")}</h2>
              <button
                type="button"
                onClick={() => setReason("one")}
                className={`home__reasons-button ${
                  reason === "one" ? "home__reasons-button--active" : ""
                }`}
              >
                <h4 className="home__reasons-subtitle">
                  {t("reasons-one-title")}
                </h4>
                <p className="home__reasons-paragraph">
                  {t("reasons-one-text")}
                </p>
              </button>
              <button
                type="button"
                onClick={() => setReason("two")}
                className={`home__reasons-button ${
                  reason === "two" ? "home__reasons-button--active" : ""
                }`}
              >
                <h4 className="home__reasons-subtitle">
                  {t("reasons-two-title")}
                </h4>
                <p className="home__reasons-paragraph">
                  {t("reasons-two-text")}
                </p>
              </button>
              <button
                type="button"
                onClick={() => setReason("three")}
                className={`home__reasons-button ${
                  reason === "three" ? "home__reasons-button--active" : ""
                }`}
              >
                <h4 className="home__reasons-subtitle">
                  {t("reasons-three-title")}
                </h4>
                <p className="home__reasons-paragraph">
                  {t("reasons-three-text")}
                </p>
              </button>
              <button
                type="button"
                onClick={() => setReason("four")}
                className={`home__reasons-button ${
                  reason === "four" ? "home__reasons-button--active" : ""
                }`}
              >
                <h4 className="home__reasons-subtitle">
                  {t("reasons-four-title")}
                </h4>
                <p className="home__reasons-paragraph">
                  {t("reasons-four-text")}
                </p>
              </button>
            </div>
          </div>
        </section>
        <section className="home__steps">
          <div className="home__steps-container">
            <h2 className="home__steps-title">{t("steps-title")}</h2>
            <div className="home__steps-grid">
              <article className="home__steps-item">
                <img
                  src={StepsOneImage}
                  alt="Step One"
                  className="home__steps-image"
                />
                <h4 className="home__steps-subtitle">{t("steps-title-one")}</h4>
                <p className="home__steps-text">{t("steps-text-one")}</p>
              </article>
              <article className="home__steps-item">
                <img
                  src={StepsTwoImage}
                  alt="Step Two"
                  className="home__steps-image"
                />
                <h4 className="home__steps-subtitle">{t("steps-title-two")}</h4>
                <p className="home__steps-text">{t("steps-text-two")}</p>
              </article>
              <article className="home__steps-item">
                <img
                  src={StepsThreeImage}
                  alt="Step Two"
                  className="home__steps-image"
                />
                <h4 className="home__steps-subtitle">
                  {t("steps-title-three")}
                </h4>
                <p className="home__steps-text">{t("steps-text-three")}</p>
              </article>
              <article className="home__steps-item">
                <img
                  src={StepsFourImage}
                  alt="Step Two"
                  className="home__steps-image"
                />
                <h4 className="home__steps-subtitle">
                  {t("steps-title-four")}
                </h4>
                <p className="home__steps-text">{t("steps-text-four")}</p>
              </article>
            </div>
            <div className="home__steps-action">
              <Link to="/platform" type="button" className="home__steps-button">
                {t("hero-learn")}
              </Link>
            </div>
          </div>
        </section>
        <section className="home__reviews">
          <h2 className="home__reviews-title">{t("review-title")}</h2>
          <img src={IconCurrency} alt="" className="home__reviews-icon-currency" />
          <img src={IconCurrency2} alt="" className="home__reviews-icon-currency home__reviews-icon-currency--last" />
          <p className="home__reviews-quotes">
            “
          </p>
          <img src={ReviewPeople3} data-number="1" alt="" className="home__reviews-icon-people" />
          <img src={ReviewPeople2} data-number="2" alt="" className="home__reviews-icon-people" />
          <img src={ReviewPeople1} data-number="3" alt="" className="home__reviews-icon-people" />
          <img src={ReviewPeople6} data-number="4" alt="" className="home__reviews-icon-people" />
          <img src={ReviewPeople5} data-number="5" alt="" className="home__reviews-icon-people" />
          <img src={ReviewPeople4} data-number="6" alt="" className="home__reviews-icon-people" />
          <Swiper
            centeredSlides
            spaceBetween={10}
            slidesPerView={1}
            pagination={{
              clickable: true,
              renderBullet(index, className) {
                return `<span class="${className}"></span>`;
              }
            }}
          >
            <SwiperSlide>
              <p className="home__reviews-text">
                {t("review-text-one")}
                <span className="home__reviews-text-name">
                  Pablo
                  <span className="home__reviews-text-position">
                    {t("review-author-one")}
                  </span>
                </span>
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="home__reviews-text">
                {t("review-text-two")}
                <span className="home__reviews-text-name">
                  Cristina
                  <span className="home__reviews-text-position">
                    {t("review-author-two")}
                  </span>
                </span>
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="home__reviews-text">
                {t("review-text-three")}
                <span className="home__reviews-text-name">
                  Arturo
                  <span className="home__reviews-text-position">
                    {t("review-author-three")}
                  </span>
                </span>
              </p>
            </SwiperSlide>
          </Swiper>
        </section>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
